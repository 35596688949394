import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import {
  SinglePost,
  PostInfo,
  PreTitle,
  Title,
  Subtitle,
  Freshnew,
  Excrept,
  CBox,
} from "../common/styles/emotion";
import Headlines from "../homepage/components/headlines"

let minutes = 1000 * 60;
let hours = minutes * 60;
let days = hours * 24;
let months = days * 30;


export default ({ data }) => {
  return (
    <div>
      <Headlines edges={data.allMarkdownRemark.edges}/>
      <CBox>
        <p>This is the blog.</p>
      </CBox>
    </div>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { frontmatter: { type: { eq: "post" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            description: metaDescription
            slug
            postImage {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

// export const query = graphql`
//   query {
//     featuredPost: allMarkdownRemark(
//       limit: 1,
//       sort: {order: DESC, fields: frontmatter___date},
//       filter: {frontmatter: {type: {eq: "post"}}}) {
//       edges {
//         node {
//           frontmatter {
//             title
//             description: metaDescription
//             slug
//             postImage {
//               childImageSharp {
//                 fluid(maxWidth: 1920) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//     headlines: allMarkdownRemark(
//       skip: 1,
//       limit: 3,
//       sort: {order: DESC, fields: frontmatter___date},
//       filter: {frontmatter: {type: {eq: "post"}}}) {
//       edges {
//         node {
//           frontmatter {
//             title
//             description: metaDescription
//             slug
//             postImage {
//               childImageSharp {
//                 fluid(maxWidth: 1920) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//     site {
//       siteMetadata {
//         description
//       }
//     }
//   }
// `
